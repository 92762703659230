; (function ($, w) {
	"use strict";
	console.log("PERSIFLEX 2021");

	const body = $('body');
	const is_touch_device = 'ontouchstart' in document.documentElement;
	const viewport = $(window).width()

	var PERSIFLEX = {
		init: () => {
			if (body.hasClass('home')) PERSIFLEX.home();
			if (body.hasClass('product-single')) PERSIFLEX.pageProduct();
			// if( body.hasClass('contato') ) PERSIFLEX.formContato(); 

			if(body.hasClass('revendedor')){
				var loginModal = new bootstrap.Modal(document.getElementById('welcomeModal'), {keyboard: false,backdrop: 'static'});
				loginModal.show();
			}

			if(body.hasClass('instalador')){
				var loginModal = new bootstrap.Modal(document.getElementById('welcomeModal'), {keyboard: false,backdrop: 'static'});
				loginModal.show();
			}

			PERSIFLEX.fixedMenu();
			PERSIFLEX.scrollTop();
			PERSIFLEX.validateForms();

			$('#agendarvisita input[type="radio"]').on('change', function() {
				console.log($(this).val()); 
				var val = $(this).val();
				$('#agendarvisita input#revenda,#agendarvisita input#profissional').prop('required',false);
				$('#agendarvisita input#'+val+'').prop('required',true);
			 });


			if (is_touch_device == true && viewport < 768){
				PERSIFLEX.mobile();
			}else{
				$('.mobile').remove();
			}
		},
		mobile: () => {
			$('.desktop').remove();
			if ($('.mobileslider').length) {
				$('.mobileslider').slick({
					dots: false,
					infinite: true,
					arrows: true,
					speed: 500,
					slidesToShow: 1,
					autoplay: true,
					adaptativeHeight: true,
					responsive: [
						{
							breakpoint: 1280,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 2
							}
						},
						{
							breakpoint: 600,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 2
							}
						},
						{
							breakpoint: 480,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1
							}
						}
					]
				});
			}
		},
		home: () => {
			PERSIFLEX.sliderVitrine();
			
		},
		pageProduct: () => {
			if ($('.slider').length) {
				$('.slider').slick({
					dots: true,
					infinite: true,
					arrows: true,
					speed: 500,
					slidesToShow: 1,
					autoplay: 0,
				});
			}

			if ($('.vitrine-produtos').length) {
				$('.vitrine-produtos').slick({
					dots: false,
					infinite: true,
					arrows: true,
					speed: 500,
					slidesToShow: 3,
					autoplay: 0,
					adaptativeHeight: false,
					responsive: [
						{
							breakpoint: 1280,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 2
							}
						},
						{
							breakpoint: 600,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 2
							}
						},
						{
							breakpoint: 480,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1
							}
						}
					]
				});
			}
		},
		sliderVitrine: () => {
			if ($('.vitrine-produtos').length) {
				$('.vitrine-produtos').slick({
					dots: false,
					infinite: true,
					arrows: true,
					speed: 500,
					slidesToShow: 3,
					autoplay: 0,
					adaptativeHeight: false,
					responsive: [
						{
							breakpoint: 1280,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 2
							}
						},
						{
							breakpoint: 600,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 2
							}
						},
						{
							breakpoint: 480,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
								arrows: false
							}
						}
					]
				});
			}
		},
		fixedMenu: () => {

			$(document).on('scroll', function () {
				var scroll = $(this).scrollTop();
				var topDist = $("body").position();
				if (scroll > topDist.top) {
					$('nav.navbar').addClass('scroll-effect');
				} else {
					$('nav.navbar').removeClass('scroll-effect');
				}
			});
		},
		scrollTop: () => {
			var btn = $('#backtop');

			$(window).on('scroll', function () {
				if ($(window).scrollTop() > 300) {
					btn.addClass('show');
				} else {
					btn.removeClass('show');
				}
			});

			btn.on('click', function (e) {
				e.preventDefault();
				$('html, body').animate({ scrollTop: 0 }, '300');
			});


		},
		validateForms:() =>{		
			  
			// Fetch all the forms we want to apply custom Bootstrap validation styles to
			var forms = document.querySelectorAll('.needs-validation')
			
			// Loop over them and prevent submission
			Array.prototype.slice.call(forms)
				.forEach(function (form) {
				form.addEventListener('submit', function (event) {
					if (!form.checkValidity()) {
					event.preventDefault()
					event.stopPropagation()
					}
			
					form.classList.add('was-validated')
				}, false)
				})
			
		}
	};

	window.onload = function () { $(PERSIFLEX.init); };
})(jQuery);

