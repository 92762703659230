window._ = require('lodash');
try {
    //window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    window.Swal = require('sweetalert2');
    window.bootstrap =  require('bootstrap');
    require('jquery-mask-plugin');
    require('slick-carousel');
    require('./main');
} catch (e) {}

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';